<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Round each of the following numerical values to four significant figures and express the
        result in standard scientific notation. To express a number in scientific notation, write E#
        or e# in place of
        <stemble-latex content="$\times 10^\#$" />. For example,
        <stemble-latex content="$1.23\text{E-4}$" />
        is equivalent to
        <stemble-latex content="$1.23\times 10^{-4}.$" />
      </p>

      <p class="mb-1">a) <stemble-latex :content="number1" /></p>
      <calculation-input v-model="inputs.input1" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-1">b) <stemble-latex :content="number2" /></p>
      <calculation-input v-model="inputs.input2" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-1">c) <stemble-latex :content="number3" /></p>
      <calculation-input v-model="inputs.input3" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-1">d) <stemble-latex :content="number4" /></p>
      <calculation-input v-model="inputs.input4" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-1">e) <stemble-latex :content="number5" /></p>
      <calculation-input v-model="inputs.input5" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'SigFigs6',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
  computed: {
    number1Integer() {
      return this.taskState.getValueBySymbol('number1Integer').content;
    },
    number2Integer() {
      return this.taskState.getValueBySymbol('number2Integer').content;
    },
    number3Integer() {
      return this.taskState.getValueBySymbol('number3Integer').content;
    },
    number4Integer() {
      return this.taskState.getValueBySymbol('number4Integer').content;
    },
    number5Integer() {
      return this.taskState.getValueBySymbol('number5Integer').content;
    },
    number1() {
      if (this.number1Integer.value === 1) {
        return '$300.235800$';
      } else if (this.number1Integer.value === 2) {
        return '$206.86500$';
      } else if (this.number1Integer.value === 3) {
        return '$104.38100$';
      } else {
        return '$150.72505$';
      }
    },
    number2() {
      if (this.number2Integer.value === 1) {
        return '$456500$';
      } else if (this.number2Integer.value === 2) {
        return '$375700$';
      } else if (this.number2Integer.value === 3) {
        return '$125890$';
      } else {
        return '$638420$';
      }
    },
    number3() {
      if (this.number3Integer.value === 1) {
        return '$0.000867510$';
      } else if (this.number3Integer.value === 2) {
        return '$0.0000619850$';
      } else if (this.number3Integer.value === 3) {
        return '$0.00217380$';
      } else {
        return '$0.000785930$';
      }
    },
    number4() {
      if (this.number4Integer.value === 1) {
        return '$90.761\\times 10^4$';
      } else if (this.number4Integer.value === 2) {
        return '$30.249\\times 10^8$';
      } else if (this.number4Integer.value === 3) {
        return '$70.846\\times 10^3$';
      } else {
        return '$80.943\\times 10^6$';
      }
    },
    number5() {
      if (this.number5Integer.value === 1) {
        return '$-0.035000$';
      } else if (this.number5Integer.value === 2) {
        return '$-0.0047000$';
      } else if (this.number5Integer.value === 3) {
        return '$-0.068900$';
      } else {
        return '$-0.0046800$';
      }
    },
  },
};
</script>
